import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { PageContent, Heading, Paragraph } from "grommet";
import { FacebookOption, Instagram } from "grommet-icons";
import Page from "../components/page";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Page kind="narrow">
      <SEO
        title="Contact | Les écuries du cerf"
        description="Vous pouvez nous contacter par téléphone, instagram et email. Les écuries du cerf, écuries de propriétaires, pensions pour chevaux à Gaudreville-la-Rivière entre Evreux et Conches-en-Ouche dans l'eure 27"
      />
      <PageContent>
        <Heading alignSelf="center">Contact</Heading>
        <Paragraph>
          Pour toute demande d'information ou d'inscription, vous pouvez
          contacter:
        </Paragraph>
        <Paragraph>📱 Leslie: 06 34 71 99 11</Paragraph>
        <Paragraph>
          📧 Mail:{" "}
          <a href="mailto:contact@lesecuriesducerf.fr">
            contact@lesecuriesducerf.fr
          </a>
        </Paragraph>
        <Paragraph>
          <FacebookOption />
          &nbsp;Facebook:&nbsp;
          <a target="_blank" href="https://facebook.com/lesecuriesducerf">
            Les écuries du Cerf
          </a>
        </Paragraph>
        <Paragraph>
          <Instagram />
          &nbsp;Instagram:&nbsp;
          <a href="https://www.instagram.com/lesecuriesducerf">
            @lesecuriesducerf
          </a>
        </Paragraph>
        <Paragraph>Retrouvez-nous</Paragraph>
        <Paragraph>
          <a target="_blank" href="https://goo.gl/maps/z3cCqJ5e86964i659">
            1 chemin du Sec Iton,
            <br />
            27190 Gaudreville-la-Rivière
          </a>
        </Paragraph>
        <ul>
          Situation géographique:
          <li>20 minutes d'Evreux</li>
          <li>10 minutes de Conches en Ouche</li>
        </ul>
        <a target="_blank" href="https://goo.gl/maps/z3cCqJ5e86964i659">
          <StaticImage objectFit="cover" src="../images/map.png" alt="Carte" />
        </a>
      </PageContent>
    </Page>
  );
};

export default IndexPage;
